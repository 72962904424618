import { gql, useMutation } from "@apollo/client";
import { AppContext } from "AppContainer";
import { parseError } from "apollo";
import Button from "components/Button";
import Input from "components/Input";
import { useContext, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const RESET_PASSWORD_CONFIRM = gql`
  mutation RESET_PASSWORD_CONFIRM($key: String!, $code: String!, $password: String!) {
    resetPasswordConfirm(key: $key, code: $code, password: $password) {
      me {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const ConfirmResetPasswordForm = () => {
  const { code, key } = useParams();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { setUser } = useContext(AppContext);
  const [confirmResetPassword, confirmResetPasswordRes] = useMutation(RESET_PASSWORD_CONFIRM, {
    onCompleted(data) {
      toast.success("Your password has been reset.");
      setUser(data.resetPasswordConfirm.me);
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  function submit(e) {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Passwords do not match.");
      return;
    }
    confirmResetPassword({ variables: { code, key, password } });
  }

  return (
    <div>
      <h5 className="text-center mt-4 font-semibold">Reset Password</h5>

      <form className="mt-6" onSubmit={submit}>
        <div>
          <Input
            className="text-center w-full"
            required
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="mt-4">
          <Input
            className="text-center w-full"
            required
            type="password"
            value={password2}
            placeholder="Confirm Password"
            onChange={(e) => setPassword2(e.target.value)}
          />
        </div>

        <div className="text-center text-sm mt-4 opacity-70">Submit your email address, we will send you an email to reset your password.</div>

        <div className="flex justify-center mt-4">
          <Button type="submit" primary loading={confirmResetPasswordRes.loading}>
            Submit
          </Button>
        </div>
      </form>

      <hr />

      <div>
        <div className="text-center text-sm mt-4">
          <Link to="/login" className="align-middle">
            <BsChevronLeft className="inline-block align-middle" />
            <span className="align-middle pl-2">Go back to Sign In</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmResetPasswordForm;
