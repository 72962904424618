import { useContext, useEffect, useState } from "react";
import Page from "components/Page";
import Button from "components/Button";
import { AppContext } from "AppContainer";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "pages/login/graphql";
import { toast } from "react-toastify";
import { parseError } from "apollo";
import { BsChevronRight } from "react-icons/bs";
import { HiOutlineUserCircle } from "react-icons/hi";
import { PiTennisBallFill } from "react-icons/pi";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaGears } from "react-icons/fa6";
import { Link } from "react-router-dom";

const UserPage = () => {
  const { user, setUser } = useContext(AppContext);
  const [supportPasskeys, setSupportPasskeys] = useState(false);
  const [signout, signoutRes] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(null);
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  useEffect(() => {
    if (
      window.PublicKeyCredential &&
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
      window.PublicKeyCredential.isConditionalMediationAvailable
    ) {
      Promise.all([
        window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
        window.PublicKeyCredential.isConditionalMediationAvailable(),
      ]).then((results) => {
        if (results.every((r) => r === true)) {
          setSupportPasskeys(true);
        }
      });
    }
  }, []);

  console.log("user", user);

  let showWis = user.isSuperuser || user.isStaff || user.groups.some((i) => i.name === "Internal");

  return (
    <Page title="Waboba ID">
      <div className="px-6 lg:p-20 container mx-auto">
        <div className="col-span-8 col-start-3">
          <div className="py-16 flex justify-center flex-col items-center">
            <div className="bg-white rounded-full border p-3">
              <HiOutlineUserCircle size={128} />
            </div>
            <div className="text-xl font-semibold mt-4">
              {user.firstName} {user.lastName}
            </div>
            <div className="opacity-70">{user.email}</div>

            <div className="mt-8 text-center">
              Waboba ID is a single sign-on service that allows you to sign in to many different Waboba services with one account.
            </div>
          </div>

          <div className="space-y-8 xl:px-8">
            <div className="space-y-8 xl:space-y-0 xl:flex xl:space-x-8">
              {showWis && (
                <a
                  href={process.env.REACT_APP_WIS_URL}
                  className="block flex-1 space-x-2 p-12 pb-8 bg-gray-800 scale-100 hover:scale-105 duration-200 text-white hover:text-white rounded-3xl text-xl shadow-lg"
                >
                  <div>
                    <PiTennisBallFill size={128} />
                  </div>
                  <div className="flex items-center justify-end space-x-2">
                    <div>Waboba Internal System - WIS</div>
                    <div>
                      <BsChevronRight />
                    </div>
                  </div>
                </a>
              )}

              <a
                href={process.env.REACT_APP_BIZ_URL}
                className="block flex-1 space-x-2 p-12 pb-8 bg-teal-600 scale-100 hover:scale-105 duration-200 text-white hover:text-white rounded-3xl text-xl shadow-lg"
              >
                <div>
                  <BiSolidPurchaseTag size={128} />
                </div>
                <div className="flex items-center justify-end space-x-2">
                  <span>Waboba Business - BIZ</span>
                  <span>
                    <BsChevronRight />
                  </span>
                </div>
              </a>

              {user.supplier && (
                <a
                  href="https://supplier.waboba.com"
                  className="block flex-1 space-x-2 p-12 pb-8 bg-sky-600 scale-100 hover:scale-105 duration-200 text-white hover:text-white rounded-3xl text-xl shadow-lg"
                >
                  <div>
                    <FaGears size={128} />
                  </div>
                  <div className="flex items-center justify-end space-x-2">
                    <span>Waboba Supplier Portal</span>
                    <span>
                      <BsChevronRight />
                    </span>
                  </div>
                </a>
              )}
            </div>

            <div className="card">
              <h5>Security</h5>
              <div className="mt-2 divide-y">
                {supportPasskeys && (
                  <Link to="passkeys" className="block py-2">
                    Passkeys
                  </Link>
                )}
                <div className="py-2">
                  <Button>Reset Password</Button>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <Button danger loading={signoutRes.loading} onClick={signout}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default UserPage;
