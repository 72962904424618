export const Spinner = ({ className = "", color = "#00bbd6", thickness = 1, size = 32, label }) => (
  <div className={`${className} flex justify-center flex-col flex-1 items-center space-y-2 z-30`}>
    <div className="lds-ring" style={{ width: size, height: size }}>
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`,
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`,
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`,
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`,
        }}
      />
    </div>
    {label ? <div className="text-xs">{label}</div> : null}
  </div>
);

export default Spinner;
