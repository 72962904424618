import Spinner from "./Spinner";

const InitializingView = () => {
  return (
    <div className="flex justify-center lg:items-center h-screen bg-cover bg-center bg-white bg-gradient-to-br from-cyan-200 dark:from-cyan-800 via-violet-300 to-fuchsia-400 dark:to-fuchsia-800">
      <Spinner label="Initializing App..." />
    </div>
  );
};

export default InitializingView;
