import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { apolloClient } from "apollo";
import AppContainer from "AppContainer";

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
      <ToastContainer hideProgressBar={true} pauseOnHover={true} closeButton={false} closeOnClick={true} />
    </ApolloProvider>
  );
}
