import { Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";

const Page = ({ title, backTo, topbarRightView, children, containerClass = "flex flex-col flex-1" }) => {
  return (
    <div className="h-screen flex-1 flex flex-col bg-gray-50 dark:bg-gray-900 overflow-auto">
      <nav className="bg-gray-50 dark:bg-gray-900 border-b dark:border-gray-700 flex justify-between items-center">
        {backTo ? (
          <div className="flex flex-1 items-center">
            <Link to={backTo} className="flex items-center justify-center space-x-2 p-6">
              <BsChevronLeft size={21} />
            </Link>
            <h2 className="py-6">{title}</h2>
          </div>
        ) : (
          <h2 className="py-6 px-10">{title}</h2>
        )}
        {topbarRightView ? <div className="flex items-center justify-center space-x-2 px-10">{topbarRightView}</div> : null}
      </nav>
      <div className={containerClass}>{children}</div>
    </div>
  );
};

export default Page;
