import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { useMutation } from "@apollo/client";
import { GetAssertReq, publicKeyCredentialToJSON } from "./auth";
import { GoPasskeyFill } from "react-icons/go";
import { LOGIN, VERFY_MFA_CODE } from "./graphql";
import { toast } from "react-toastify";
import { parseError } from "apollo";
import { AppContext } from "AppContainer";
import { axiosInstance } from "http";
import Input from "components/Input";
import Button from "components/Button";

const LoginForm = () => {
  const { user, setUser } = useContext(AppContext);
  const [email, setEmail] = useState(process.env.REACT_APP_TEST_LOGIN_EMAIL || "");
  const [password, setPassword] = useState(process.env.REACT_APP_TEST_LOGIN_PASSWORD || "");
  const [userId, setUserId] = useState(null);
  const [enableMfa, setEnableMfa] = useState(false);
  const [showLoginInWithPasskeys, setShowLoginInWithPasskeys] = useState(false);
  const [passkeyLoading, setPasskeyLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [secureLogin, secureLoginRes] = useMutation(LOGIN, {
    variables: { email, password, device: localStorage.getItem("device") },
    onCompleted: (data) => {
      if (data.secureLogin.loginDirectly) {
        const redirectTo = queryString.parse(location.search).redirectTo;
        if (redirectTo) {
          window.open(redirectTo, "_self");
        } else {
          setUser(data.secureLogin.me);
          toast.success("You are logged in.");
        }
      } else if (data.secureLogin.enableMfa) {
        setUserId(data.secureLogin.userId);
        setEnableMfa(data.secureLogin.enableMfa);
      }
    },
    onError: (error) => {
      toast.error(parseError(error));
    },
  });

  async function signInWithPassskeys() {
    setPasskeyLoading(true);
    try {
      let res = await axiosInstance.get("passkeys/auth/begin");
      const abortController = new AbortController();
      let options = GetAssertReq(res.data);
      options.signal = abortController.signal;
      const credential = await navigator.credentials.get(options);
      const passkeys = JSON.stringify(publicKeyCredentialToJSON(credential));
      let data = new FormData();
      data.append("passkeys", passkeys);
      data.append("username", "");
      data.append("password", "");
      res = await axiosInstance.post("login-with-passkeys", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.data.success) {
        const redirectTo = queryString.parse(location.search).redirectTo || "/";
        window.open(redirectTo, "_self");
      }
    } catch (error) {
      setPasskeyLoading(false);
      if (error.name === "NotAllowedError") return;
      toast.error(parseError(error));
    }
  }

  async function checkIfPasskeyIsAvailable() {
    if (window.PublicKeyCredential && window.PublicKeyCredential.isConditionalMediationAvailable) {
      const isCMA = await window.PublicKeyCredential.isConditionalMediationAvailable();
      if (isCMA) setShowLoginInWithPasskeys(true);
    }
  }

  useEffect(() => {
    checkIfPasskeyIsAvailable();
  }, []);

  useEffect(() => {
    if (user) {
      let redirectTo = queryString.parse(location.search).redirectTo || "/me";
      navigate(redirectTo);
    }
  }, [user, location.search, navigate]);

  const [verifyMfaCode, verifyMfaCodeRes] = useMutation(VERFY_MFA_CODE, {
    variables: { userId, code },
    onCompleted: (data) => {
      localStorage.setItem("device", data.verifyMfaCode.device);
      const redirectTo = queryString.parse(location.search).redirectTo;
      if (redirectTo) {
        window.open(redirectTo, "_self");
      } else {
        setUser(data.verifyMfaCode.user);
        toast.success("You are logged in.");
      }
    },
    onError: (error) => {
      toast.error(parseError(error));
    },
  });

  function submit(e) {
    e.preventDefault();
    if (enableMfa) {
      verifyMfaCode();
    } else {
      secureLogin();
    }
  }

  return (
    <div>
      <h4 className="text-center mt-4">Sign In</h4>
      <form className="mt-6" onSubmit={submit}>
        <div>
          <Input
            className="text-center w-full"
            required
            type="email"
            autoComplete="email webauthn"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Input
            className="text-center w-full"
            required
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {enableMfa ? (
          <div>
            <div className="mt-4">
              <Input className="text-center w-full" value={code} autoFocus={true} placeholder="Authentication Code" onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className="mt-4 flex justify-center">
              <Button loading={verifyMfaCodeRes.loading} type="submit">
                {verifyMfaCodeRes.loading ? "Signing in..." : "Sign In"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-8 flex justify-center">
            <Button loading={secureLoginRes.loading} type="submit">
              {secureLoginRes.loading ? "Signing in..." : "Sign in with Password"}
            </Button>
          </div>
        )}
      </form>

      <div className="text-xs opacity-70 text-center mt-4">
        <Link className=" text-sky-600 font-normal" to="reset-password">
          Reset Password
        </Link>
      </div>

      {showLoginInWithPasskeys ? (
        <div className="flex justify-center border-t dark:border-gray-700 border-opacity-80 my-6 pt-8">
          <Button primary onClick={signInWithPassskeys} loading={passkeyLoading} icon={<GoPasskeyFill size={18} />}>
            Sign In with Passkeys
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
