import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import Button from "components/Button";
import Input from "components/Input";
import { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const REQUEST_RESET_PASSWORD = gql`
  mutation($email: String!) {
    requestResetPassword(email: $email) {
      requested
    }
  }
`;

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [requestResetPassword, requestResetPasswordRes] = useMutation(REQUEST_RESET_PASSWORD, {
    onCompleted() {
      toast.success("We have sent you an email to reset your password.");
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  function submit(e) {
    e.preventDefault();
    console.log("submit");
    requestResetPassword({ variables: { email } });
  }

  return (
    <div>
      <h5 className="text-center mt-4 font-semibold">Reset Password</h5>

      <form className="mt-6" onSubmit={submit}>
        <div>
          <Input className="text-center w-full" required type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="text-center text-sm mt-4 opacity-70">Submit your email address, we will send you an email to reset your password.</div>

        <div className="flex justify-center mt-4">
          <Button type="submit" primary loading={requestResetPasswordRes.loading}>
            Submit
          </Button>
        </div>
      </form>

      <hr />

      <div>
        <div className="text-center text-sm mt-4">
          <Link to="/" className="align-middle">
            <BsChevronLeft className="inline-block align-middle" />
            <span className="align-middle pl-2">Go back to Sign In</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
