import Page from "components/Page";
import { FETCH_USER_PASSKEYS, UPDATE_PASSKEY } from "../graphql";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "apollo";
import { toast } from "react-toastify";
import { axiosInstance } from "http";
import { MakeCredReq, publicKeyCredentialToJSON } from "pages/login/auth";
import moment from "moment";
import Button from "components/Button";

const PasskeysPage = () => {
  const { loading, error, data } = useQuery(FETCH_USER_PASSKEYS);
  const [updatePasskey, updatePasskeyRes] = useMutation(UPDATE_PASSKEY, {
    onCompleted() {
      toast.success("Passkey updated.");
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  async function createPasskey() {
    var res = await axiosInstance.get("passkeys/reg/begin");
    try {
      const publicKey = MakeCredReq(res.data);
      const credential = await navigator.credentials.create(publicKey);
      res = await axiosInstance.post("passkeys/reg/complete", JSON.stringify(publicKeyCredentialToJSON(credential)));
    } catch (error) {
      if (error.name === "NotAllowedError") return;
      toast.error(parseError(error));
    }
  }

  function onChange(id, enabled) {
    updatePasskey({ variables: { id, enabled } });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const passkeys = data.me.passkeys;

  console.log("updatePasskeyRes", updatePasskeyRes);

  return (
    <Page title="Passkeys" backTo="./..">
      <div className="p-10">
        <div className="max-w-4xl">
          <div className="card">
            <h4>My Passkeys</h4>
            <div className="mt-2 -mx-2 whitespace-nowrap">
              <table>
                <thead>
                  <tr>
                    <th className="pr-4">Name</th>
                    <th>Last Used</th>
                    <th className="text-right">Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {passkeys.map((passkey) => (
                    <tr key={passkey.id} className="border-y">
                      <td className="pr-4">
                        {passkey.name} ({passkey.platform})
                      </td>
                      <td>{moment(passkey.lastUsed).format("YYYY-MM-DD HH:mm:ss")}</td>
                      <td className="w-full">
                        <div className="flex items-center justify-end space-x-2">
                          {updatePasskeyRes.loading ? <span className="text-xs opacity-70">Uplating...</span> : null}
                          <input type="checkbox" checked={passkey.enabled} onChange={(e) => onChange(passkey.id, e.target.checked)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <Button onClick={createPasskey}>+ Passkey</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PasskeysPage;
