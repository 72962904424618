import { gql } from "@apollo/client";

export const FETCH_USER_PASSKEYS = gql`
  query FETCH_USER_PASSKEYS {
    me {
      passkeys: userpasskeySet {
        id
        name
        enabled
        platform
        addedOn
        lastUsed
      }
    }
  }
`;

export const UPDATE_PASSKEY = gql`
  mutation UPDATE_PASSKEY($id: ID!, $enabled: Boolean!) {
    updatePasskey(id: $id, enabled: $enabled) {
      passkey {
        id
        enabled
      }
    }
  }
`;
