import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "./AppContainer";
import AuthPages from "pages/login";
import UserPage from "pages/user";
import queryString from "query-string";
import PasskeysPage from "pages/user/passkeys";

const AppRoutes = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  if (user) {
    const redirectTo = queryString.parse(location.search).redirectTo;
    if (redirectTo) {
      window.open(redirectTo, "_self");
      return null;
    }

    return (
      <Routes>
        <Route index element={<UserPage />} />
        <Route path="passkeys" element={<PasskeysPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return <AuthPages />;
};

export default AppRoutes;
