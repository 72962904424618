import { ApolloClient, InMemoryCache } from "@apollo/client";

export function parseError(error) {
  var message = error.message;
  if (error.graphQLErrors) message = error.graphQLErrors.map((err) => err.message).join(",");
  if (error.networkError) message = error.networkError.result.errors.map((err) => err.message).join(",");
  return message;
}

export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache(),
  credentials: "include",
});
