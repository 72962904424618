import { gql } from "@apollo/client";

export const FETCH_RANDOM_IMAGE = gql`
  query FETCH_RANDOM_IMAGE {
    randomPromoImage {
      id
      url
    }
  }
`;

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!, $device: String) {
    secureLogin(email: $email, password: $password, device: $device) {
      userId
      enableMfa
      loginDirectly
      me {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
    }
  }
`;

export const VERFY_MFA_CODE = gql`
  mutation VERFY_MFA_CODE($userId: ID!, $code: String!) {
    verifyMfaCode(userId: $userId, code: $code) {
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
      device
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      isLogout
    }
  }
`;
