import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL + "/api",
  timeout: 60000,
  withCredentials: true,
});

export function download(url, filename) {
  axiosInstance
    .get(url, { timeout: 60000, responseType: "blob" })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      alert(err);
    });
}
