const Input = ({ className = "", valid = true, ...rest }) => {
  return (
    <input
      className={`px-2 py-1 rounded-xl appearance-none bg-gray-100 bg-opacity-80 w-full
             dark:bg-gray-900 dark:text-gray-200 disabled:cursor-not-allowed placeholder-opacity-30 placeholder:text-sm
             ${valid ? "focus:border-sky-600 dark:focus:border-sky-700" : "border-red-500 dark:border-red-800"}
             ${className}
             `}
      {...rest}
    />
  );
};

export default Input;
